.root {

  height: 100%;
  background: rgb(249 249 249);
  /* position: relative; */
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%);
  display: block !important;
  box-sizing: border-box;
  overflow: hidden;
}

.a {
  color: rgb(29, 82, 182);
  background-color: rgb(188, 45, 45);
  list-style: none;
  cursor: pointer;
  font: inherit;
  /* margin-left: 15px; */
  text-decoration: none;

}

.li1 {
  position: relative;
  font-family: 'SF Pro Text';
  font-weight: 500;
  line-height: 20px;
  border-bottom: 1px solid rgb(229 230 233);
  text-transform: uppercase;
  /* padding: 15px 0px; */
  padding: 0;
  font-size: 14px;
  color: rgb(92 99 115);

  /* background-color: rgb(240 240 240); */
  margin-left: 0;
  display: list-item;
  text-align: -webkit-match-parent;
}

.li1 a {
  padding: 15px 15px 15px 20%;
  display: flex;
  color: rgb(144, 153, 170);
  align-items: center;
}

/* .li1:hover, .li1:focus {
  background-color:rgb(240 240 240);
} */
.li2 {
  /* margin-bottom: 30px; */
  font-size: 17px;
  /* margin-left: 20%; */
  margin-right: 15px;

}

a {
  /* background-color:  rgb(240, 240, 240) */
  color: rgb(92 99 115);
  text-decoration: none;
}

/* a:hover {
color:  rgb(92 99 115);
}  */
/* a:active {
  background-color: red
}  */
/* .testActive {
  color: white !important;
  background-color: rgb(240 240 240) !important;
} */
a.active {
  background-color: rgb(240, 240, 240) !important;
  padding: '17px 83px 17px 5px';
  color: rgb(92, 99, 115);
}

.info-tlt {
  margin-top: 50px;
  margin-left: 20%;
  font-size: 15px;
  font-family: "SF Pro Text";
  color: rgb(92, 99, 115);
}

.info-noContacts {
  /* margin-top: 50px; */
  margin-left: 20%;
  font-size: 15px;
  font-family: "SF Pro Text";
  /* color: rgb(92, 99, 115); */
  color: #9e9d9d;
  margin-bottom: 30px;
}

.info-items {
  margin-left: 20%;
  font-size: 14px;
  margin-bottom: 12px;
  line-height: 1.4;
  color: rgb(144, 153, 170);
  font-family: 'SF Pro Text';
}

.info-loader {
  margin-left: 20%;
  margin-bottom: 10px;
}

.info-edit {
  /* margin-top: 30px; */
  margin-left: 20%;
  cursor: pointer;
  color: rgb(72, 146, 195);
  font-size: 13px;
}

.info-edit:hover {
  color: rgb(72, 146, 195);
}