.gm-style .gm-style-iw-c {
    background-color: #4892C3 !important;
    color: #FFF !important;
    max-width: 150px !important;
    max-height: 150px !important;
    padding: 5px !important;
}

.gm-style .gm-style-iw-d {
    overflow: auto !important;
}

.gm-style .gm-style-iw-c button {
    display: none !important;
}

.gm-style .gm-style-iw-tc {
    display: none;
}
.gm-style .gm-style-iw-t{
    bottom: 50px !important;
}