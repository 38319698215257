@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
/* @import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap'); */

/* src: local("SFTextLight"), url('./assets/fonts/SF-Pro-Text-Light.eot') format("embedded-opentype"); */
@font-face {
    font-family: "SFTextLight";
    /* src: url('/fonts/SF-Pro-Text-Light.eot')format("embedded-opentype"),
  url('/fonts/SF-Pro-Text-Light.otf');   */
    src: local('SFTextLight'), url(./assets/fonts/SF-Pro-Text-Light.otf) format('opentype');
}

@font-face {
    font-family: "SFTextMedium";
    /* src: ur1`l('/fonts/SF-Pro-Text-Medium.eot')format("embedded-opentype"),
       url('/fonts/SF-Pro-Text-Medium.otf'); */
    src: local('SFTextMedium'), url(./assets/fonts/SF-Pro-Text-Medium.otf) format('opentype');
}

@font-face {
    font-family: "SF Pro Text";
    /* src: url('/fonts/SF-Pro-Text-Regular.eot')format("embedded-opentype"),
       url('/fonts/SF-Pro-Text-Regular.otf'); */
    src: local('SF Pro Text'), url(./assets/fonts/SF-Pro-Text-Regular.otf) format('opentype');
}

@font-face {
    font-family: "SFTextBold";
    /* src: url('/fonts/SF-Pro-Text-Bold.eot')format("embedded-opentype"),
       url('/fonts/SF-Pro-Text-Bold.otf'); */
    src: local('SFTextBold'), url(./assets/fonts/SF-Pro-Text-Bold.otf) format('opentype');
}

@font-face {
    font-family: "SFTextSemibold";
    /* src: url('/fonts/SF-Pro-Text-Semibold.eot')format("embedded-opentype"),
       url('/fonts/SF-Pro-Text-Semibold.otf'); */
    src: local('SFTextSemibold'), url(./assets/fonts/SF-Pro-Text-Semibold.otf) format('opentype');
}




body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif
    ; */
    font-family: 'SF Pro Text';
    /* font-family: 'Source Sans Pro'; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;

}

/* @font-face {
  font-family: 'MyFont';
  src: local('MyFont'), url(./assets/fonts/MyFont.woff) format('woff');
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


/* .css-dev-only-do-not-override-9ntgx0.ant-switch.ant-switch-checked {
  background: rgb(72, 146, 195);
}
.css-dev-only-do-not-override-9ntgx0.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: rgb(72, 146, 195);
} */

.mainDiv1 {
    width: 100%;
    float: 'left'
}

.pageTitle {
    font-family: 'SFTextSemibold';
    text-align: left;
    margin-left: 0;
    margin-top: 0;
    /* margin-bottom: 35px; */
    font-size: 22px;
    width: 100%;
    font-weight: 600;
    color: rgb(92, 99, 115);
    display: block
}

.formGrid {
    justify-content: space-between !important;
    width: 100% !important;
    max-width: 77% !important
}

.gridText {
    max-width: 45% !important;
    padding-left: 0 !important;
    padding-top: 0 !important;
    margin-bottom: 28px !important;
}

.gridText.settingtText {
    max-width: 200px !important;
}

.gridText.textArea {
    max-width: 60% !important;
}

.lable-style {
    color: rgb(78, 145, 192) !important;
    font-size: 15px !important;
    /* font: inherit !important */
    font-family: "SF Pro Text" !important;
}

.lable-style.lable-red {
    color: rgb(225, 108, 108) !important;
}

.textColor {
    color: rgb(84, 84, 84);
}

.logout-section {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.logoutIcon {
    /* background-image: url(/images/log-out-default.svg); */
    background-image: url(./assets/images/log-out-default.svg);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
}

.logout-section:hover .logoutIcon {
    background-image: url(./assets/images/log-out-active.svg);
}

.logout-btn {
    font-family: "SF Pro Text";
    font-size: 16px;
    margin-left: 0;
    color: rgb(144, 153, 170);
    cursor: pointer;
}

.logout-section:hover .logout-btn {
    color: rgb(72, 146, 195);
}

.coming-soon {
    font-size: 30px;
    font-weight: 600;
    z-index: 100;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.heading-section {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 0px;
    margin-bottom: 35px;
}

.heading-section.mb-48 {
    margin-bottom: 48px;
}

.serch-section {
    display: flex;
    -webkitB-box-pack: justify;
    justify-content: space-between;
}

.btn-plus-icon {
    font-size: 30px;
    margin-right: 10px;
}

.custome-tbl {
    margin-top: 20px;
}

.custome-tbl th {
    padding: 19px 10px 19px 0;
    white-space: nowrap;
    font-family: "SF Pro Text";
    font-size: 13px;
    color: rgb(144, 153, 170);
    font-weight: 500
}

.custome-tbl th span svg {
    font-size: 15px;
    margin-left: 3px;
    margin-top: 2px
}

.custome-tbl td {
    padding: 19px 10px 19px 0;
    display: table-cell;
    color: rgb(92, 99, 115);
    font-family: "SF Pro Text";
    font-size: 13px;
    position: relative;
}

.custom-table-header {
    color: rgb(144, 153, 170) !important;
    font-weight: 500;
    font-family: "SF Pro Text"
}

.custom-table-header:hover {
    color: rgb(144, 153, 170) !important;
}

.custom-table-header:focus {
    color: rgb(144, 153, 170) !important;
}

.boxColor {
    color: rgba(0, 0, 0, 0.87) !important;
}

/* .setting-tab{
  font-family: SF Pro Text !important;
  color: rgb(78, 145, 192) ;
  font-weight: 500 !important;
} */

.checkbox-ui {
    padding: 0 !important;
    width: 16px;
    height: 16px;
    color: rgb(162, 169, 183) !important;
    margin-right: 12px !important;
}

.checkbox-ui.Mui-checked {
    color: rgb(72, 146, 195) !important;
}

.checkbox-ui+span {
    font-family: 'SFTextLight';
    font-size: 15px;
}

.checkbox-lable {
    color: rgb(113, 117, 125);
    margin-bottom: 45px !important;
}

.dropdoen-arrow {
    cursor: 'pointer';
    position: 'absolute';
    right: 0;
    z-index: -1;
    color: rgb(133, 142, 160)
}

.totalCount {
    /* font-family: 'SFTextLight'; */
    /* .totalCount { */
    font-family: 'SFTextLight';
    font-size: 17px;
    margin-left: 3px;
    color: rgb(144, 153, 170);
    font-weight: 200 !important
}

.checked-icon {
    background-color: none !important;
}

.checked-icon:hover {
    background-color: none !important;
}

.norecordFound {
    text-align: center !important;
    font-family: "SF Pro Text !important";
    font-size: 15px !important;
}

.map {
    position: relative !important;
    height: var(--height) !important;
    width: var(--width) !important;
}

.dialog {
    height: 100% !important;
}

.search-input::placeholder {
    color: rgb(144, 153, 170);
}

/*.search-input:focus {
  border-color: #000;
}*/
.font20 {
    font-size: 20px;
}

.default-number {
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(72, 146, 195);
    padding: 2px;
    border-radius: 4px;
    color: #fff;
    line-height: 1;
    font-size: 12px;
    white-space: nowrap;
}

.default-number-sec {
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    color: #f13535;
    white-space: nowrap;
}

.default-number-sidebar {
    /* position: absolute; */
    top: 0 !important;
    left: 0 !important;
    color: #f13535;
    white-space: nowrap;
}

.text-area {
    font-family: 'SFTextLight !important';
    font-size: '12px !important'
}

.actioneye {
    color: rgb(133, 142, 160) !important;
    font-size: 16px !important;
    margin-left: 5px;
    cursor: pointer;
}

.actioneye:hover {
    color: rgb(72, 146, 195) !important;
}

.list {
    background-color: aqua;
}

.test-mode {
    /* position: absolute; */
    top: 0px;
    left: 0px;
    height: 40px;
    /* width: 100%; */
    background-color: rgb(72, 146, 195);
    padding-left: 40px;
    color: rgb(255, 255, 255);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 15px;
    margin: -40px -30px 40px;

}

.mapicon {
    color: #858EA0;
    font-size: 17px;
    cursor: pointer
}

.mapicon:hover {
    color: rgb(72, 146, 195) !important;
}

.logDetails {
    line-height: 32px;
}

.logDetails b {
    font-size: 0.95rem;
}

.logDetails p {
    font-size: 0.95rem;
}



.multiselect-tenate fieldset.MuiOutlinedInput-notchedOutline {
    border: none;
}

.multiselect-tenate .MuiInputBase-formControl {
    max-height: 100px;
    overflow: auto;
    border: 1px solid #ddd;
}