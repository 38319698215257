
.login-container {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.loginTxt {
    color: #5C6373;
    text-align: center;
    font-size: 26px;
    margin-bottom: 82px;
    margin-top: 0;
    font-weight: 650; 
    font-family:'SFTextSemibold';
}

.loginFeild {
    display: flex;
    margin-bottom: 25px; 
    font-family: 'SFTextLight';
    align-items:flex-start;
    
}
.loginFeild i {
    margin-right: 16px;
    margin-bottom: 8px;
    /* color: rgb(174, 180, 192) */
}

.loginFeild .icon-eye {
    position: absolute;
    right: 0;
    margin: 0px;
    cursor: pointer;
}

.forgot-link:hover {
    text-decoration: underline;
    color: rgb(72, 146, 195);
}

.loginForm {
    display: flex;
    flex-direction: column;
    /* max-width: 435px; */
    max-width: 40%;
    width: 100%;
    height: 85vh;
    justify-content: center;
    margin: 0 auto;
}
    /* @media (max-width: 768px) {
        width: 90%
    } */
/*}

.i {
    padding-top: 20px;
    color: rgb(174, 180, 192);
    height: 20px !important;
    width: 20px !important;
    margin-right: 14px !important
}

/* 

.inputField, .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
    width: 100%;
    color: rgb(144, 153, 170) !important;
    font-family: 'SFTextLight' !important;
    font-size: 15px !important;
    font-weight: 300 !important;
}
.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: rgb(144, 153, 170) !important;
}
.Mui-focused {
    color: rgb(144, 153, 170) !important;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input {
    border-bottom: 1px solid rgba(174, 180, 192, 0.24) !important;
}
.css-whebh7-MuiInputBase-root-MuiInput-root:after {
    display: none;
}
.css-whebh7-MuiInputBase-root-MuiInput-root:before {
    display: none;
} */
/* .css-sghohy-MuiButtonBase-root-MuiButton-root {
    background: rgb(72, 146, 195) !important;
    text-transform: capitalize !important;
    padding: 15px !important;
    width: 100% !important;
    margin-top: 40px !important;
    line-height: 1.2 !important;
    border-radius: 8px !important;
    box-shadow: rgb(98 132 152 / 33%) 0px 11px 26px -10px !important;
    font-family: "SF Pro Text" !important;
    font-size: 16px !important;
} */
/* .css-sghohy-MuiButtonBase-root-MuiButton-root.SaveBtn {
    margin: 0 !important;
} */
/* .css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
    background:rgb(62, 138, 193) !important;
} */
/* .css-1d1r5q-MuiFormHelperText-root{
    color:red !important
} */




