.changeBtn {
    width: 30% !important;
    padding: 12px !important;
    opacity: 0.7;
    border-radius: 12px !important;
    margin-right: 200px !important;
}

.changePasswordBtn {
    margin: 20px auto 65px !important;
    max-width: 190px;
    color: #fff !important;

}

.icon {
    align-self: flex-end;
    margin-right: 15px;
    padding-top: 15px;
    font-size: 25px;
    cursor: pointer;
    color: rgb(113, 117, 125);
}

.dialogBox {
    border-radius: 12px !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 430px !important;
    border-radius: 7px !important;
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    font-family: SFTextSemibold !important;
    font-size: 22px !important;
    color: rgb(92, 99, 115) !important;
}

.css-16z3zeq-MuiInputBase-root-MuiInput-root:after {
    display: none;
}

.css-16z3zeq-MuiInputBase-root-MuiInput-root:before {
    border-bottom: solid 1px rgba(174, 180, 192, 0.24) !important;
}

.css-1z10yd4-MuiFormControl-root-MuiTextField-root {
    margin-bottom: 8px !important;
}