.changeBtn {
    width: 30% !important;
    padding: 12px !important;
    opacity: 0.7;
    border-radius: 12px !important;
    margin-right: 200px !important;
}

.changePasswordBtn {
    margin: 20px auto 65px !important;
    max-width: 190px;
    color: #fff !important;

}

.icon {
    align-self: flex-end;
    margin-right: 15px;
    padding-top: 15px;
    font-size: 25px;
    cursor: pointer;
    color: rgb(113, 117, 125);
}

.dialogBox {
    border-radius: 12px !important;
}
